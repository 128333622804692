import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundColor: "#fff",
    height: "auto",
    width: "100%",
    padding: "20px 150px",
    textAlign: "center",
  },
  head: {
    fontSize: "40px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },

  text: {
    color: "#262525",
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "center",
  },

  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    main: {},

    head: {
      paddingTop: "0px",
      fontSize: "34px",
      fontWeight: " 800",
    },
    text: {
      fontSize: "22px",
    },
  },

  "@media (max-width: 420px)": {
    main: {
      margin: "0px",
      padding: "50px 0px",
    },
    head: {
      fontSize: "24px",
      lineHeight: "26px",
      marginBottom: "10px",
    },
    text: {
      padding: "0px 20px",
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
}));

const Stat = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.main}>
        <Box>
          {" "}
          <Grid sm={12} md={12} container>
            {" "}
            <Grid Item xs={6} sm={4}>
              <Box className={classes.head}>50+</Box>
              <Box className={classes.text}> Clients</Box>
            </Grid>{" "}
            <Grid Item xs={6} sm={4}>
              <Box className={classes.head}>80%</Box>
              <Box className={classes.text}>Profile Relevence Rate</Box>
            </Grid>
            <Grid Item xs={12} sm={4}>
              <Box className={classes.head}>500+</Box>
              <Box className={classes.text}>Executive Search</Box>
            </Grid>
            {/* <Grid Item xs={6} sm={2} >
              <Box className={classes.head}>Above 30%</Box>
              <Box className={classes.text}>Diversity Ratio</Box>
            </Grid>
            <Grid Item xs={6} sm={2} >
              <Box className={classes.head}>5000+</Box>
              <Box className={classes.text}>Total Candidate's Places</Box>
            </Grid>
            <Grid Item xs={6} sm={2} >
              <Box className={classes.head}>15 Days</Box>
              <Box className={classes.text}>Average TAT for Selection</Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Stat;
