import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import BackgroundImg from "../../../../assets/images/Our.png";
import { makeStyles } from "@mui/styles";
import customer from "../../../../assets/images/Picture3.png";
import tech from "../../../../assets/images/Picture2.png";
import integrity from "../../../../assets/images/Picture1.png";
import innovation from "../../../../assets/images/Picture4.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "65px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "700",
    color: "#262525",
    textAlign: "center",
  },
  heading1: {
    fontSize: "30px",
    fontWeight: "700",
    color: "#262525",
    textAlign: "center",
    marginBottom: "20px",
  },
  image: { width: "280px", height: "180px" },

  text: {
    color: "#262525",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "60px",
    marginTop: "20px",
  },
  text1: {
    color: "#262525",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "10px",
  },

  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    image: { width: "300px", height: "200px" },
  },

  "@media (max-width: 420px)": {
    main: {
      padding: "0px 15px ",
    },
    container: {
      padding: "60px 10px 10px",
      width: "100%",
    },
    heading: {
      paddingTop: "22px",
      fontSize: "24px",
      lineHeight: "26px",
      marginBottom: "20px",
    },
    heading1: {
      fontSize: "20px",
      marginBottom: "20px",
    },
    gridContainer: { padding: "0px 15px " },
    text1: {
      fontSize: "20px",
      fontWeight: "600",
      textAlign: "center",
      marginTop: "5px",
      marginBottom: "10px",
    },
    text: {
      marginTop: "0px",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
}));

const OurMission = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.main} id="mission">
        <Box className={classes.container}>
          {" "}
          <Box>
            {" "}
            <p className={classes.heading}>Our Mission</p>
          </Box>
          <Box>
            <p className={classes.text}>
              We aim to provide the best executive search, consulting and
              advisory services to our clients. We work towards the goal of
              finding the right leaders/solutions & help business achieve its
              objectives.
            </p>
          </Box>
          <Box>
            {" "}
            <p className={classes.heading1}>Core Values</p>
          </Box>
          <Box>
            {" "}
            <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={classes.gridContainer}
            >
              {" "}
              <Grid Item xs={12} md={3} sm={6} sx={{ textAlign: "center" }}>
                <Box className={classes.image} component="img" src={customer} />
                <p className={classes.text1}>Customer Care</p>
              </Grid>{" "}
              <Grid Item xs={12} md={3} sm={6} sx={{ textAlign: "center" }}>
                <Box className={classes.image} component="img" src={tech} />
                <p className={classes.text1}>Technology</p>
              </Grid>
              <Grid Item xs={12} md={3} sm={6} sx={{ textAlign: "center" }}>
                <Box
                  className={classes.image}
                  component="img"
                  src={integrity}
                />
                <p className={classes.text1}>Integrity</p>
              </Grid>
              <Grid Item xs={12} md={3} sm={6} sx={{ textAlign: "center" }}>
                <Box
                  className={classes.image}
                  component="img"
                  src={innovation}
                />
                <p className={classes.text1}>Innovation</p>
              </Grid>{" "}
            </Grid>
          </Box>
          <Box>
            {" "}
            {/* <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={classes.gridContainer}
            >
              {" "}
              <Grid Item xs={12} md={3} sm={6}>
                <Box>
                  
                </Box>
              </Grid>{" "}
              <Grid Item xs={12} md={3} sm={6}>
                <Box>
                  <p className={classes.text1}>Technology</p>
                </Box>
              </Grid>
              <Grid Item xs={12} md={3} sm={6}>
                <Box>
                  <p className={classes.text1}>Integrity</p>
                </Box>
              </Grid>
              <Grid Item xs={12} md={3} sm={6}>
                <Box>
                  <p className={classes.text1}>Innovation</p>
                </Box>
              </Grid>{" "}
            </Grid> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default OurMission;
