import React from "react";

const CustomDots = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button
      className={active ? "active" : "inactive"}
      style={{
        background: active ? "#3DCD84" : "initial",
        color: active ? "#3DCD84" : "initial",
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        border: "2px solid #3DCD84 ",
        margin: "0px 5px",
      }}
      onClick={() => onClick()}
    >
      {" "}
    </button>
  );
};

export default CustomDots;
