import { getAccessToken, setTokenResponse } from "./authData";
import { http } from "./client";
import { logoutSuccess } from "../Store/auth";

/**
 * As importing store here will create a cyclic dependency
 * So this is a work-around to get dispatch working from here
 * Once store has been initialized, it will call setDispatch
 * method provided here
 */
let _dispatch;
export const setDispatch = (dispatch) => (_dispatch = dispatch);

export const handleResponse = (response) => {
  if (response.config.url === "/o/token/") {
    setTokenResponse(response.data);
  }
  return response.data;
};

let newToken = null;

export const handleError = async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      _dispatch(logoutSuccess());
    }
    throw error.response.data;
  }
  throw error.message;
};

export const setAuthorizationHeader = async (config) => {
  const headers = config.headers;
  headers["Authorization"] = "Bearer " + getAccessToken();
  return { ...config, headers };
};
