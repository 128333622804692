import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import Footer from "../../../../components/footer/footer";
import BackgroundImg from "../../../../assets/images/why-le.png";
import cxoNetwork from "../../../../assets/images/CXO Networking.png";
import ourExperience from "../../../../assets/images/Experience.png";
import ourExpertise from "../../../../assets/images/Expertise.png";
import ourTeam from "../../../../assets/images/Team.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    paddingTop: "50px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  heading1: {
    marginTop: "30px",
    fontSize: "24px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  image: {
    marginTop: "30px",
    width: "120px",
    height: "120px",
  },
  box_content: { padding: "50px 100px 0px" },
  text: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "500",
    textAlign: "left",
  },
  text1: {
    color: "#3DCD84",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "left",
    // marginTop: "10px",
  },
  text2: {
    textAlign: "left",

    lineHeight: "18px",
  },
  list: { textAlign: "left", padding: "0px 0px 0px 15px" },

  container: {
    padding: "50px ",
    textAlign: "center",
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    main: {
      marginTop: "50px",
    },

    heading: {
      paddingTop: "0px",
      fontSize: "34px",
      fontWeight: " 800",
    },
    text: {
      fontSize: "22px",
    },
  },

  "@media (max-width: 420px)": {
    main: {
      padding: "0px 15px 20px",
      backgroundImage: "none",
      textAlign: "left",
    },
    heading: {
      marginTop: "100px",
      fontSize: "24px",
      textAlign: "center",
      lineHeight: "30px",
      marginBottom: "20px",
    },

    image: { display: "none" },
    text: {
      textAlign: "center",
      fontSize: "18px",
      lineHeight: "22px",
    },
    box_content: { padding: "0px " },
    container: {
      padding: "0px ",
    },
    text2: {
      textAlign: "left",
      margin: "0px 10px 10px",
      fontSize: "16px",
      lineHeight: "20px",
    },
    list: { textAlign: "left", padding: "0px 0px 0px 40px" },
  },
}));
const WhyLEPage = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box>
            <p className={classes.heading}>Why LE?</p>
          </Box>
          <Box>
            <p className={classes.text}>
              Our unique value proposition comes from a powerful network, domain
              expertise, our experience from the ground and our diverse team. We
              take up collaborative approach by keeping our clients and
              candidates on a common platform. We do continuous market research
              to know about the demand and supply of talent pool in the
              industry.
            </p>
          </Box>
          <Box className={classes.box_content}>
            <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <Grid Item xs={12} sm={3}>
                {" "}
                <Box
                  className={classes.image}
                  component="img"
                  src={cxoNetwork}
                />
              </Grid>
              <Grid Item xs={12} sm={9}>
                {" "}
                <p className={classes.text1}>CXO Network</p>
                <p className={classes.text2}>
                  LinkCXO ( Sister concern of LeadersEdge) is a trustworthy
                  intelligent platform to change the way CXOs/Senior Leaders
                  collaborate globally with over 5000+ CxOs on board.
                </p>
                <ul className={classes.list}>
                  <li>
                    High quality personalized content and personal engagement
                  </li>
                  <li>
                    Technology aided back end with AI & web crawlers enabled
                    passive search.
                  </li>
                  <li>
                    Jobs Section to connect senior leaders with the right career
                    opportunity
                  </li>
                  <li>Business news to stay updated</li>
                  <li>AI-Enabled personalized smart assistant – Lxie.</li>
                </ul>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.box_content}>
            {" "}
            <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <Grid Item xs={12} sm={9}>
                {" "}
                <p className={classes.text1}>Our Experience</p>
                <p className={classes.text2}>
                  We have worked with multiple large size entities and foreign
                  MNCs to finding the right executives and leadership for their
                  organisation
                </p>
                <ul className={classes.list}>
                  <li>
                    We have 100,000+ CXOs connected across industries and have
                    served more than 50 premium clients globally.
                  </li>
                  <li>
                    We have completed 100+ executive assignments last year.
                  </li>
                  <li>
                    We take pride of our client retention rate being over 90%.
                  </li>
                  <li>
                    We have developed our own internal database of talent pool
                    with the help of our network of leaders and executives
                    across the globe.
                  </li>
                </ul>
              </Grid>
              <Grid Item xs={12} sm={3}>
                {" "}
                <Box
                  className={classes.image}
                  component="img"
                  src={ourExperience}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.box_content}>
            {" "}
            <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <Grid Item xs={12} sm={3}>
                {" "}
                <Box
                  className={classes.image}
                  component="img"
                  src={ourExpertise}
                />
              </Grid>
              <Grid Item xs={12} sm={9}>
                {" "}
                <p className={classes.text1}>Our Expertise</p>
                <p className={classes.text2}>
                  We specialize in Executive Search, Consulting & Advisory
                  Services.
                </p>
                <ul className={classes.list}>
                  <li>
                    We are a group of companies: Leaders’Edge, ExoTalent
                    (Mid-Level Search & RPO Services) and LinkCXO.
                  </li>
                  <li>
                    We customize every position distinctly & collect adequate
                    understanding of the role deliverable of today and tomorrow.
                  </li>
                  <li>
                    We move forward through collaborative and consultative
                    approach with our clients to understand the talent landscape
                    & sharpen the search process.
                  </li>
                  <li>
                    We go deep to Mine Leaders. Three level of detailed
                    assessment will be done, using our 360-degree executive
                    assessment framework
                  </li>
                  <li>
                    We monitor all the processes till the executive has joined
                    and continue to track & present backup profiles, if needed
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.box_content}>
            <Grid
              sm={12}
              md={12}
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <Grid Item xs={12} sm={9}>
                {" "}
                <p className={classes.text1}>Our Team</p>
                <p className={classes.text2}>
                  LinkCXO is a trustworthy intelligent platform to change the
                  way CXOs/Senior Leaders collaborate globally
                </p>
                <ul className={classes.list}>
                  <li>
                    Our Core team has experience working with top executive
                    firms and consulting companies with a wide network at global
                    level.
                  </li>
                  <li>
                    We have a Panel of senior industry leaders from Premium
                    Institutes (IITs, IIMs and Other) for candidate assessment
                    and assignment execution.
                  </li>
                </ul>
              </Grid>
              <Grid Item xs={12} sm={3}>
                {" "}
                <Box className={classes.image} component="img" src={ourTeam} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
export default WhyLEPage;
