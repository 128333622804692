import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../components/Header/Navbar";
import Footer from "../../../components/footer/footer";
import BackgroundImg from "../../../assets/images/WhatBG.png";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Search from "../../../assets/images/Picture11.png";

import Consulting from "../../../assets/images/ConsultingAdvisory.png";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  backgroundColor: "inherit",
  color: "#fff",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: "none",
  border: "none",
  color: "#3DCD84",
  backgroundColor: "inherit",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "none",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    paddingTop: "30px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  heading1: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "left",
  },
  imageFrame: {
    // padding: "60px 0px",
  },
  image: {
    marginTop: "50px",
    marginLeft: "70px",
    width: "300px",
    height: "200px",
    backgroundColor: "#fff",
  },

  text: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
  },
  text1: {
    color: "#3DCD84",
    fontSize: "20px",
    fontWeight: "600",
    marginTop: "10px",
  },
  text2: {
    margin: "0px 10px",
    lineHeight: "18px",
  },
  marketing: { padding: "0px 140px" },
  container: {
    padding: "60px 100px",
    textAlign: "left",
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    heading: {
      paddingTop: "0px",
      fontSize: "34px",
      fontWeight: " 800",
    },
  },

  "@media (max-width: 420px)": {
    main: {
      marginTop: "80px",
    },
    imageFrame: {
      padding: "0px",
    },
    image: {
      marginTop: "20px",
      marginLeft: "20px",
    },
    text1: {
      textAlign: "center",
    },
    text: {
      textAlign: "center",
      fontSize: "18px",
      lineHeight: "20px",
    },
    heading1: {
      marginTop: "30px",
      fontSize: "20px",
      textAlign: "center",
    },
    heading: {
      fontSize: "24px",

      lineHeight: "28px",
    },

    container: {
      padding: "0px 20px",
    },
  },
}));
const WhatWeDoPage = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChangeAcc = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box>
            <p className={classes.heading}>What We Do?</p>
          </Box>
          <Grid
            sm={12}
            md={12}
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid Item xs={12} sm={8}>
              <Box>
                <p className={classes.heading1}>Executive Search</p>{" "}
                <div
                  style={{
                    width: "150px",
                    height: "2px",
                    marginBottom: "10px",
                    backgroundColor: "#C8C8C8",
                  }}
                ></div>
              </Box>
              <Box>
                <p className={classes.text}>
                  Every firm wants great leaders and need for high-performing
                  executives remains constant. We at LeadersEdge recognize the
                  need and merge our individual talents with the collective
                  expertise of our global network to bring you the right
                  candidates. We move forward through collaborative and
                  consultative approach with our clients to understand the
                  talent landscape & sharpen the search process. Our 360-degree
                  executive assessment framework process ensures that our
                  clients get the best Leader.{" "}
                </p>
              </Box>
            </Grid>
            <Grid Item xs={12} sm={4} className={classes.imageFrame}>
              {" "}
              <Box className={classes.image} component="img" src={Search} />
            </Grid>
          </Grid>

          <Box>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAcc("panel1")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>CXO Mapping</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We have a huge network of CXOs and a vast database of
                  candidates which help us in Competition Mapping and
                  Organizational Structure Mapping.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAcc("panel2")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Niche Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Technology aided back end with AI & web crawlers enabled
                  passive search. We go deep to Mine Leaders. Our expertise is
                  into niche searches such as IoT, Blockchain, AI, ML, EV, AV,
                  DS, Cloud, Mobility, Connected Technology, Cybersecurity etc.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChangeAcc("panel3")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Diversity Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We emphasize our search on diversity reach of candidates.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChangeAcc("panel4")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>State of the art assessments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We mine new age capabilities additionally in today's context
                  as emotional intelligence, tech agility, distributed teams’
                  mindset, personal leadership, remote behavior sensing &
                  decisions, transformational abilities & dissecting expertise &
                  gen management capabilities.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChangeAcc("panel5")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Start-Ups & Greenfield Set-Ups</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We have profound expertise in setting up the Senior management
                  team for Start-ups and greenfield projects.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Grid
            sm={12}
            md={12}
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid Item xs={12} sm={8}>
              <Box>
                <p className={classes.heading1}>Consulting&Advisory</p>

                <div
                  style={{
                    width: "150px",
                    height: "2px",
                    marginBottom: "10px",
                    backgroundColor: "#C8C8C8",
                    // "@media (max-width: 420px)": {
                    //   width: "250px",
                    // },
                  }}
                ></div>
              </Box>
              <Box>
                <p className={classes.text}>
                  LeadersEdge is uniquely qualified to assist its clients with
                  the important and complex task of Top Team Effectiveness,
                  Succession planning, Executive assessment, M&A, Organisation
                  structuring and many more Talent Consulting & Advisory
                  services and solutions. We provide the insight that our
                  clients rely on to select and develop executives, to make
                  crucial decisions, optimize the team and evaluate and evolve
                  their organizational cultures.
                </p>
              </Box>
            </Grid>
            <Grid Item xs={12} sm={4} className={classes.imageFrame}>
              {" "}
              <Box className={classes.image} component="img" src={Consulting} />
            </Grid>
          </Grid>
          <Box>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChangeAcc("panel6")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Top Team Effectiveness</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Organizations that pursue and sustain high-performing senior
                  leadership teams are more likely to outperform the competition
                  and realize breakout success. We work with the CEO & the top
                  team to deliver the management promise.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChangeAcc("panel7")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Leadership Restructuring</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This is an area of expertise across various domains & we help
                  our clients restructure their roles & keep it relevant.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChangeAcc("panel8")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Leadership Assimilation</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This offering enables a hired executive to land into the
                  system well. The cultural & chemistry match is done to enable
                  the leader to integrate with the system. This is done through
                  the channel of coaching.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChangeAcc("panel9")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Executive Assessment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Our experienced consultants and senior industry leaders on the
                  panel advise our clients with exhaustive insights and data to
                  assess senior leadership / CXOs.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChangeAcc("panel10")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Organisation Structure</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We help our clients design the right Organisation structure to
                  deliver the business objective. This exercise is detailed and
                  gets into the depth of each role's responsibility,
                  accountability & delivery.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChangeAcc("panel11")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>
                  Merger & Acquisition Leadership Advisory
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Every M&A has a potential issue of getting into Leadership
                  Surplus or Deficit. We help our clients with integration,
                  re-design, change management and land the entire leadership
                  into new roles.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChangeAcc("panel12")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Capability Building</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We provide capability building frameworks & delivery models
                  that allows our clients to reinforce skills, knowledge &
                  behaviours & enhance organisational performance.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChangeAcc("panel13")}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "none",
                }}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Succession planning</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We bring an integrated and systematic approach for identifying
                  and developing right successor/s for C level /Critical roles.
                  We follow a detailed process methodology and knowledge
                  transfer along with change management.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
export default WhatWeDoPage;
