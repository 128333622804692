import React from "react";
import { Grid, Box, Link, Typography, Stack } from "@mui/material";
import Logo from "../../assets/images/LELogoWhiteGreen.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  back: {
    backgroundColor: "#262525",
    height: "auto",
    padding: "50px 0px 0px 0px",
    width: "100%",
    margin: "0px",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  img: {
    width: "250px",
    height: "120px",
  },
  gridcontainer: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor:"red",
  },

  contactus: {
    color: "#fff",
    fontSize: "38px",
    fontWeight: "700",
    marginBottom: "30px",
  },
  city: {
    color: "#3DCD84",
    fontSize: "24px",
    fontWeight: "700",
    marginTop: "30px",
  },
  address: {
    color: "#FEFFFF",
    fontSize: "16px",
    paddingRight: "10px",
  },
  phone: {
    color: "#FEFFFF",
    fontSize: "16px",
  },
  email: { color: "#FEFFFF", fontSize: "16px" },
  tc: { color: "#FEFFFF", fontSize: "14px", margin: "0px 30px" },
  mediaicon: {
    width: "30px",
    height: "30px",
    marginTop: "30px",
  },
  "@media (max-width: 1068px)": {
    back: {
      paddingLeft: "40px",
      width: "100%",
    },
  },

  "@media (max-width: 420px)": {
    back: {
      padding: " 30px 0px",
      width: "100%",
      margin: "0px",
      textAlign: "center",
    },
    mediaicon: {
      marginLeft: "10px",
      marginRight: "20px",
    },
    gridcontainer: {
      width: "100%",
      margin: "0",
      padding: "0",
    },
    img: {
      width: "100px",
      height: "80px",
    },
    tc: { fontSize: "12px", margin: "0px 10px" },
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.back} id='contactus'>
      {" "}
      <Typography
        variant='h3'
        gutterBottom
        component='div'
        sx={{
          textAlign: "center",
          color: "#fff",
          fontSize: "38px",
          "@media (max-width: 420px)": { fontSize: "26px" },
        }}>
        Contact Us
      </Typography>
      <Grid
        justify='center'
        // alignItems="center"
        sm={12}
        md={12}
        container
        className={classes.gridcontainer}>
        {/* <Grid
          Item
          xs={12}
          md={2.6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {" "}
          <Box component='img' className={classes.img} src={Logo} />
        </Grid> */}
        <Grid Item xs={12} md={2} sm={6}>
          <Box className={classes.city}>Delhi/NCR</Box>{" "}
          <Box className={classes.address}>Awfis Majestic Signia,</Box>{" "}
          <Box cssName={classes.address}>1st Floor, Majestic Signia, </Box>{" "}
          <Box className={classes.address}>Plot No. A-27, Block A,</Box>{" "}
          <Box className={classes.address}>Industrial Area, Sector 62,</Box>{" "}
          <Box className={classes.address}>Noida, Uttar Pradesh - 201309</Box>{" "}
          <Box className={classes.phone}>Phone: +91 9871311206</Box>
        </Grid>
        <Grid Item xs={12} md={2.3} sm={6}>
          {" "}
          <Box className={classes.city}>Bengaluru</Box>{" "}
          <Box className={classes.address}>
            133/1, 4th Floor, Yashkesh Tower,
          </Box>{" "}
          <Box className={classes.address}>10th Main, 14th Cross,</Box>{" "}
          <Box className={classes.address}>6th Sector, HSR Layout,</Box>{" "}
          <Box className={classes.address}>Bengaluru - 560102.</Box>{" "}
          <Box className={classes.phone}>Phone: +91 9513007253</Box>
        </Grid>
        <Grid Item xs={12} md={2.3} sm={6}>
          {" "}
          <Box className={classes.city}>Mumbai</Box>{" "}
          <Box className={classes.address}>211, 2nd Floor,</Box>{" "}
          <Box className={classes.address}>B Wing, Western Edge II,</Box>{" "}
          <Box className={classes.address}>Behind Metro Super Market,</Box>{" "}
          <Box className={classes.address}>Near Magathane, Borivali,</Box>{" "}
          <Box className={classes.address}>Mumbai, Maharashtra - 400066.</Box>{" "}
          <Box className={classes.phone}>Phone: +91 9930408319</Box>
        </Grid>
        <Grid Item xs={12} md={2.5} sm={6}>
          {" "}
          <Box className={classes.city}>Navi Mumbai</Box>{" "}
          <Box className={classes.address}>AWFIS, 2nd Floor, Raghuleela, </Box>{" "}
          <Box className={classes.address}>Arcade Vishwaroop,</Box>{" "}
          <Box className={classes.address}>Infotech Pvt. Ltd. </Box>{" "}
          <Box className={classes.address}>Plot No 34, 35, 38, </Box>{" "}
          <Box className={classes.address}>Mumbai, Maharashtra 400703</Box>{" "}
          <Box className={classes.phone}>Phone: +91 9137779517</Box>
        </Grid>
        <Grid Item xs={12} md={2} sm={6}>
          {" "}
          <Box className={classes.city}>Pune</Box>{" "}
          <Box className={classes.address}>Solitaire Business Hub,</Box>{" "}
          <Box className={classes.address}>Unit no. 6070B, H Wing,</Box>{" "}
          <Box className={classes.address}>opp. NECO Garden Society,</Box>{" "}
          <Box className={classes.address}>Clover Park, Viman Nagar,</Box>{" "}
          <Box className={classes.address}>Pune, Maharashtra - 411014</Box>{" "}
          <Box className={classes.phone}>Phone: +91 9930408319</Box>
        </Grid>
      </Grid>
      <Box className={classes.tc} borderBottom={1} py={{ xs: 5, sm: 4 }}>
        {" "}
      </Box>
      <Box className={classes.tc} textAlign='left' py={{ xs: 5, sm: 1 }}>
        &copy;&nbsp;2022 &nbsp;leadersedge.company&nbsp;|&nbsp;
        <a href='/' style={{ color: "white" }}>
          Privacy Policy
        </a>{" "}
        &nbsp;|&nbsp;{" "}
        <a href='/' style={{ color: "white" }}>
          Terms & Conditions
        </a>
      </Box>
    </Box>
  );
};
export default Footer;
