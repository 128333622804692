import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import BackgroundImg from "../../../../assets/images/why-le.png";
import { makeStyles } from "@mui/styles";
import cxoNetwork from "../../../../assets/images/CXO Networking.png";
import ourExperience from "../../../../assets/images/Experience.png";
import ourExpertise from "../../../../assets/images/Expertise.png";
import ourTeam from "../../../../assets/images/Team.png";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  image: { margin: "0px 30px", width: "80px", height: "80px" },
  textbox: {
    width: "auto",
    height: "180px",
  },
  text: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "60px",
    marginTop: "20px",
  },
  text1: {
    color: "#3DCD84",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "10px",
  },
  text2: {
    margin: "0px 10px",
    fontSize: "16px",
    lineHeight: "20px",
  },
  container: {
    padding: "60px",
    textAlign: "center",
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    container: {
      padding: "60px 100px",
      textAlign: "center",
    },
    heading: {
      paddingTop: "0px",
    },
  },

  "@media (max-width: 420px)": {
    container: {
      padding: "80px 10px 10px 10px",
    },
    main: {
      margin: "0px",
      padding: "0px",
      backgroundImage: "none",
      width: "100%",
      height: "auto",
    },
    heading: {
      fontSize: "26px",
      textAlign: "center",
      lineHeight: "26px",
      marginBottom: "20px",
    },
    image: { marginTop: "20px", width: "60px", height: "60px" },
    textbox: {
      paddingBottom: "20px",
      width: "auto",
      height: "100px",
    },
    text1: {
      fontSize: "18px",
      lineHeight: "20px",
      fontWeight: "500",
      margin: "5px 0px",
    },
    text: {
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "18px",
    },
    text2: {
      marginBottom: "30px ",
    },
  },
}));

const WhyLe = () => {
  const navigate = useNavigate();
  const handleSeeMore = () => {
    navigate("/why_le_page");
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.main} id="whyle">
        <Box className={classes.container}>
          {" "}
          <Box>
            {" "}
            <p className={classes.heading}> Why LE?</p>
          </Box>
          <Box>
            <p className={classes.text}>
              Our unique value proposition comes from a powerful network, domain
              expertise, our experience from the ground and our diverse team. We
              take up collaborative approach by keeping our clients and
              candidates on a common platform. We do continuous market research
              to know about the demand and supply of talent pool in the
              industry.
            </p>
          </Box>
          <Box>
            {" "}
            <Grid sm={12} md={12} container>
              {" "}
              <Grid Item xs={12} md={3} sm={6}>
                <Box
                  className={classes.image}
                  component="img"
                  src={cxoNetwork}
                />
                <Box className={classes.textbox}>
                  <p className={classes.text1}>CXO Network</p>
                  <p className={classes.text2}>
                    LinkCXO ( Sister concern of LeadersEdge) is a trustworthy
                    intelligent platform to change the way CXOs/Senior Leaders
                    collaborate globally with over 5000+ CxOs on board
                  </p>
                </Box>
              </Grid>{" "}
              <Grid Item xs={12} md={3} sm={6}>
                <Box
                  className={classes.image}
                  component="img"
                  src={ourExperience}
                />
                <Box className={classes.textbox}>
                  <p className={classes.text1}>Our Experience</p>
                  <p className={classes.text2}>
                    We have worked with multiple large size entities and foreign
                    MNCs to finding the right executives and leadership for
                    their organisation
                  </p>
                </Box>
              </Grid>
              <Grid Item xs={12} md={3} sm={6}>
                <Box
                  className={classes.image}
                  component="img"
                  src={ourExpertise}
                />
                <Box className={classes.textbox}>
                  <p className={classes.text1}>Our Expertise</p>
                  <p className={classes.text2}>
                    We specialize in Executive Search, Consulting & Advisory
                    Services
                  </p>
                </Box>
              </Grid>
              <Grid Item xs={12} md={3} sm={6}>
                <Box className={classes.image} component="img" src={ourTeam} />
                <Box className={classes.textbox}>
                  <p className={classes.text1}>Our Team</p>
                  <p className={classes.text2}>
                    We have a team of SMEs and consulting advisors who work with
                    our clients to bring transformation in their ecosystem
                  </p>
                </Box>
              </Grid>{" "}
            </Grid>
          </Box>
          <Box className={classes.knowbtn}>
            <Button
              onClick={handleSeeMore}
              variant="standard"
              sx={{
                marginTop: "25px",
                backgroundColor: "none",
                color: "#3DCD84",
                fontSize: "22px",
                lineHeight: "26px",
                textTransform: "none",
                fontWeight: " 800",
                padding: "10px 20px",
                "&:hover": {
                  color: "#3DCD84",
                  backgroundColor: "none",
                },
                "@media (max-width: 420px)": {
                  fontSize: "18px",
                  margin: "20px 0px",
                  padding: "4px 20px",
                },
              }}
            >
              See More
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default WhyLe;
