const config = {
  serverBaseUrl: "http://15.207.95.117:3000",
};

// const config = {
//   serverBaseUrl: "https://lxweb-api.tk",
// };

export default config;

// export const LinkedInApi = {
//   clientId: "81c214xc47sapn",
//   // redirectUrl: "http://linkcxo-development-frontend.s3-website.ap-south-1.amazonaws.com/redirect-web",
//   // redirectUrl: "https://web.linkcxo.com/redirect-web",
//   redirectUrl: "https://linkcxo.com/redirect-web",
//   // redirectUrl: "https://d23mx6z4vkk9zi.cloudfront.net/redirect-web",
//   oauthUrl:
//     "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
//   scope: "r_liteprofile%20r_emailaddress%20w_member_social",
//   state: "123456",
// };
// export const NodeServer = {
//   baseURL: "http://localhost",
//   getUserCredentials: "/getUserCredentials",
// };
// let DBMode = "";
// let configlinkedin = {
//   SERVER_IP: "",
//   REDIRECT_URL: "",
//   baseURL: "",
//   getUserCredentials: "/getUserCredentials",
//   oauthUrl:
//     "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
//   scope: "r_liteprofile%20r_emailaddress",
//   state: "123456",
// };
