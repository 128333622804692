import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { HashLink as Link } from "react-router-hash-link";
import DrawerComponent from "./Drawer";
import Logo from "../../assets/Icons/leadersedge_logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "0px 0px 5px 5px",
      marginTop: theme.spacing(0),
      minWidth: 100,

      "& .MuiMenu-list": {
        textDecoration: "none",
        backgroundColor: "#BEEFD7",
        padding: "0px 13px 0px 0px",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          textDecoration: "none",
          backgroundColor: "#F2F2F2",
          fontSize: 18,
          color: "#fff",
          marginRight: 0,
          marginTop: 0,
        },
        "&:active": {
          backgroundColor: "#BEEFD7",
        },
      },
    },
  })
);
const useStyles = makeStyles((theme) => ({
  appbarnavstyle: {
    padding: "0px 47px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  img: {
    marginTop: "10px",
    width: "250px",
    height: "120px",
  },
  menu: {
    textDecoration: "none",
    textTransform: "none",
    fontWeight: "700",
    fontSize: "16px",
    padding: "15px",
    color: "#262525",
  },
  "@media (max-width: 1100px)": {
    appbarnavstyle: {
      paddingLeft: "0px",
    },
    menu: {
      padding: "0px 10px",
    },
  },

  "@media (max-width: 420px)": {
    logo: {
      flexGrow: "0",
      cursor: "pointer",
    },
    appbarnavstyle: {
      paddingLeft: "0px",
    },
    img: {
      marginTop: "10px",
      width: "180px",
      height: "100px",
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
    setIsActive(true);
    setIsActive3(false);
    setIsActive1(false);
  };
  const handleMenuClick1 = (event) => {
    setIsActive1(true);
    setIsActive3(false);
    setIsActive(false);
  };
  const handleMenuClick2 = (event) => {
    // setAnchorEl(event.currentTarget);
    setIsActive2((current) => !current);
  };
  const handleMenuClick3 = (event) => {
    // setAnchorEl(event.currentTarget);
    setIsActive3(true);
    setIsActive1(false);
    setIsActive(false);
  };

  const handleMenuClose = (e) => {
    setAnchorEl(null);
    setIsActive(true);
  };
  const handleMenuCloseTeam = () => {
    setIsActive(true);
    setAnchorEl(null);
    navigate("/team");
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <AppBar
      className={classes.appbarnavstyle}
      position="fixed"
      sx={{
        backgroundColor: "#F2F2F2",
        zIndex: "999999900",
      }}
    >
      <CssBaseline />
      <Toolbar>
        <Box className={classes.logo}>
          {" "}
          <Link to="/#home">
            {" "}
            <Box component="img" className={classes.img} src={Logo} />
          </Link>
        </Box>

        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Button
              diableRipple
              aria-control="menu"
              varient="contained"
              sx={{
                textDecoration: "none",
                textTransform: "none",
                fontWeight: "700",
                fontSize: "16px",
                padding: "21px 15px",
                color: "#262525",
                borderRadius: "0",
                backgroundColor: isActive ? "#BEEFD7" : "",
                "&:hover": {
                  backgroundColor: isActive ? "#BEEFD7" : "",
                },
              }}
              endIcon={
                <KeyboardArrowDownIcon
                  style={{
                    padding: "none",
                    left: 10,
                    margin: "none",
                    border: "none",
                    color: "#262525",
                    backgroundColor: "none",
                    fontSize: 25,
                  }}
                />
              }
              onClick={handleMenuClick}
            >
              About Us
            </Button>
            <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {" "}
              <Link to="/#whyle" sx={{ textDecoration: "none" }}>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    textDecoration: "none",
                    backgroundColor: "#BEEFD7",
                    color: "#262525",
                    fontWeight: "700",
                    fontSize: "16px",
                    "&:hover": {
                      textDecoration: "none",
                      backgroundColor: "#BEEFD7",
                      color: "#262525",
                    },
                  }}
                >
                  {" "}
                  Why LE{" "}
                </MenuItem>{" "}
              </Link>
              <Link to="/team" sx={{ textDecoration: "none" }}>
                <MenuItem
                  onClick={handleMenuCloseTeam}
                  sx={{
                    backgroundColor: "#BEEFD7",
                    color: "#262525",
                    fontWeight: "700",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#BEEFD7",
                      color: "#262525",
                    },
                  }}
                >
                  Teams
                </MenuItem>{" "}
              </Link>
              <Link to="/#mission" sx={{ textDecoration: "none" }}>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    backgroundColor: "#BEEFD7",
                    color: "#262525",
                    fontWeight: "700",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#BEEFD7",
                      color: "#262525",
                    },
                  }}
                >
                  Our Mission & Core Values
                </MenuItem>{" "}
              </Link>
              <Link to="/#client" sx={{ textDecoration: "none" }}>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    backgroundColor: "#BEEFD7",
                    color: "#262525",
                    fontWeight: "700",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#BEEFD7",
                      color: "#262525",
                    },
                  }}
                >
                  Client Testimonial
                </MenuItem>{" "}
              </Link>
              <Link to="/#candidate" sx={{ textDecoration: "none" }}>
                <MenuItem
                  onClick={handleMenuClose}
                  sx={{
                    backgroundColor: "#BEEFD7",
                    color: "#262525",
                    fontWeight: "700",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#BEEFD7",
                      color: "#262525",
                    },
                  }}
                >
                  Candidate Testimonial
                </MenuItem>{" "}
              </Link>
            </StyledMenu>
            <Button
              sx={{
                borderRadius: "0",
                backgroundColor: isActive1 ? "#BEEFD7" : "",
                "&:hover": {
                  backgroundColor: isActive1 ? "#BEEFD7" : "",
                },
              }}
              onClick={handleMenuClick1}
            >
              <Link to="/#what_we" className={classes.menu}>
                What We Do
              </Link>
            </Button>
            {/* <Button
              // sx={{
              //   padding: "20px",
              //   borderRadius: "0",
              //   backgroundColor: isActive2 ? "#BEEFD7" : "",
              //   "&:hover": {
              //     backgroundColor: isActive2 ? "#BEEFD7" : "",
              //   },
              // }}
              onClick={handleMenuClick2}
            >
              <Link to="/#industries" smooth className={classes.menu}>
                Industries
              </Link>
            </Button> */}
            {/* <Button
              // sx={{
              //   padding: "20px",
              //   borderRadius: "0",
              //   backgroundColor: isActive3 ? "#BEEFD7" : "",
              //   "&:hover": {
              //     backgroundColor: isActive3 ? "#BEEFD7" : "",
              //   },
              // }}
              onClick={handleMenuClick3}
            >
              <Link to="/#industries" smooth className={classes.menu}>
                Clients
              </Link>
            </Button> */}
            <Button
              sx={{
                borderRadius: "0",
                backgroundColor: isActive3 ? "#BEEFD7" : "",
                "&:hover": {
                  backgroundColor: isActive3 ? "#BEEFD7" : "",
                },
              }}
              onClick={handleMenuClick3}
            >
              <Link to="/#clients_logo" className={classes.menu}>
                Clients
              </Link>
            </Button>
            {/* <Button>
              <Link
                to="/JobsPage"
                smooth
                className={classes.menu}
                onClick={scrollToTop}
              >
                Jobs
              </Link>
            </Button> */}

            {/* <Button
              sx={{
                textTransform: "none",
                textDecoration: "none",
                padding: "4px 15px",
                borderRadius: "5px ",
                color: "#38CD84",
                marginLeft: "20px",
                fontWeight: "700",
                fontSize: "18px",
                backgroundColor: "#262525",
                "&:hover": {
                  backgroundColor: "#262525",
                  color: "#38CD84 ",
                },

                "@media (max-width: 1100px)": {
                  padding: "3px 15px",
                },
              }}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleClick}
            >
              Join As
            </Button>
            <StyledMenu anchorEl={anchorEl1} open={open1} onClose={handleClose}>
              <MenuItem
                sx={{
                  backgroundColor: "#262525",
                  color: "#3DCD84",
                  fontWeight: "700",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#262525",
                    color: "#3DCD84",
                  },
                }}
                onClick={() => {
                  navigate("/loginPageCompany");
                }}
              >
                Company
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: "#262525",
                  color: "#3DCD84",
                  fontWeight: "700",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#3DCD84",
                  },
                }}
                onClick={() => {
                  navigate("/loginPageCandidate");
                }}
              >
                Candidate
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: "#262525",
                  color: "#3DCD84",
                  fontWeight: "700",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#3DCD84",
                  },
                }}
                onClick={() => {
                  navigate("/recruiterRegister");
                }}
              >
                Recruiter
              </MenuItem>
            </StyledMenu> */}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
