import React from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "../container/home/home_page";
import Team from "../container/home/about_us/Our_team/teams";
import WhyLEPage from "../container/home/about_us/why_LE/why_le_detail_page";
import WhatWeDoPage from "../container/home/what_we_do/what_we_detail_page.jsx";
import WhatWeMobile from "../container/home/what_we_do/what_we_mobile_view";
// import CompanyRegister from "../container/RegisterPage/companyRegister";
// import CompanyRegister2 from "../container/RegisterPage/companyRegister2";
// import CandidateRegister from "../container/RegisterPage/candidateRegister";
// import RecruiterRegister from "../container/RegisterPage/recruiterRegister";
// import JobsPage from "../container/Jobs/Common/JobsPage";
// import JobSearchResult from "../container/Jobs/Common/JobSearchResultPage";
// import JobDescriptionBeforeSignup from "../container/Jobs/Common/JobDescriptionBeforeSignup";
// import OtpVerification from "../container/OTP_Page/otpVerification";
// import CompanyProfile from "../container/Jobs/CompanyJobPages/CompanyProfile";
// import CompanyJD from "../container/Jobs/CompanyJobPages/CompanyJd";
// import CreateJob from "../container/Jobs/CompanyJobPages/createJob";
// import JobDescriptionCandidate from "../container/Jobs/CandidateJobPages/JobDescriptionCandidate";
// import CandidateProfile from "../container/Jobs/CandidateJobPages/CandidateProfile";
// import CandidateFeedback from "../container/Jobs/CandidateJobPages/CandidateFeedback";
// import JobDescriptionWithdraw from "../container/Jobs/CandidateJobPages/JobDescriptionWithdrawCandidate";
// import LoginPageCandidate from "../container/LoginPage/loginPageCandidate";
// import LoginPageCompany from "../container/LoginPage/loginPageCompany";
const BasicRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" name="home" element={<Home />} />
      </Routes>
      <Routes>
        <Route exact path="/team" name="team" element={<Team />} />
      </Routes>
      <Routes>
        <Route
          exact
          path="/why_le_page"
          name="why_le_page"
          element={<WhyLEPage />}
        />
        <Route
          exact
          path="/what_we_do_detail"
          name="what_we_do_detail"
          element={<WhatWeDoPage />}
        />
        <Route
          exact
          path="/what_we_mob"
          name="what_we_mob"
          element={<WhatWeDoPage />}
        />
      </Routes>
    </Router>
  );
};

export default BasicRoutes;
