import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { HashLink as Link } from "react-router-hash-link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginLeft: "210px",
    borderRadius: "5px",
    marginTop: theme.spacing(64),
    minWidth: 80,

    "& .MuiMenu-list": {
      backgroundColor: "#BEEFD7",
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
    justifyContent: "flex-end",
    backgroundColor: "#BEEFD7",
    color: "#262525",
  },
  MuiDrawer: {
    backgroundColor: "#F3F2FB",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawerRoot: {
    position: "relative !important",
    marginTop: "100px",
    "& .MuiBackdrop-root": {
      marginTop: "100px",
      position: "relative !important",
      height: "100vh",
    },
  },
  drawerPaper: {
    position: "absolute !important",
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setAnchorEl(null);
  };
  const scrollToTop = () => {
    setOpenDrawer(false);
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };
  const handleCloseTeam = () => {
    setAnchorEl(null);
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    navigate("/team");
  };
  return (
    <>
      <Drawer
        className={classes.drawerCss}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem>
            <ListItemText
              iconComponent={
                <KeyboardArrowDownIcon
                  style={{
                    padding: "none",
                    left: 10,
                    margin: "none",
                    border: "none",
                    color: "#fff",
                    backgroundColor: "none",
                    fontSize: 25,
                  }}
                />
              }
              sx={{ color: "#fff" }}
              onClick={(event) => handleClick(event)}
            >
              About Us
            </ListItemText>
          </ListItem>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {" "}
            <MenuItem
              component={Link}
              to="/#whyle"
              onClick={handleClose}
              sx={{
                backgroundColor: "#BEEFD7",
                color: "#262525",
                fontWeight: "700",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#BEEFD7",
                  color: "#262525",
                },
              }}
            >
              Why LE
            </MenuItem>
            <MenuItem
              component={Link}
              to="/team"
              onClick={handleCloseTeam}
              sx={{
                backgroundColor: "#BEEFD7",
                color: "#262525",
                fontWeight: "700",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#BEEFD7",
                  color: "#262525",
                },
              }}
            >
              Teams
            </MenuItem>
            <MenuItem
              component={Link}
              to="/#mission"
              onClick={handleClose}
              sx={{
                backgroundColor: "#BEEFD7",
                color: "#262525",
                fontWeight: "700",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#BEEFD7",
                  color: "#262525",
                },
              }}
            >
              Our Mission & Core Values
            </MenuItem>
            <MenuItem
              component={Link}
              to="/#client"
              onClick={handleClose}
              sx={{
                backgroundColor: "#BEEFD7",
                color: "#262525",
                fontWeight: "700",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#BEEFD7",
                  color: "#262525",
                },
              }}
            >
              Client Testimonial
            </MenuItem>
            <MenuItem
              component={Link}
              to="/#candidate"
              onClick={handleClose}
              sx={{
                backgroundColor: "#BEEFD7",
                color: "#262525",
                fontWeight: "700",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#BEEFD7",
                  color: "#262525",
                },
              }}
            >
              Candidate Testimonial
            </MenuItem>
          </StyledMenu>
          <Divider />
          <ListItem onClick={scrollToTop} component={Link} to="/what_we_mob">
            <ListItemText sx={{ color: "#fff" }}>What We Do</ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/#clients_logo"
          >
            <ListItemText sx={{ color: "#fff" }}>Clients</ListItemText>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: "100px", zIndex: "999999" }}
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}
      >
        <MenuIcon sx={{ color: "#262525" }} />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
