import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import theme from "./theme/index";
import configureStore from "./Store";
import BasicRoutes from "./routes/index";
import "./App.css";


const { persistor, store } = configureStore();
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BasicRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
