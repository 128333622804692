import React, { useState } from "react";
import { Box, Button } from "@mui/material";
// import quote from "../../../assets/images/Quote1.png";
import { makeStyles } from "@mui/styles";
// import ClientTestimonialBg from "../../../../assets/images/";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica Neue",
  },
  container: {
    height: "auto",
    padding: "20px 0px",
  },
  content: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "30px 10px 10px",
  },
  name: {
    marginTop: "30px",
    color: "#3DCD84",
    fontSize: "18px",
    fontWeight: "600",
  },
  designation: {
    height: "40px",
    width: "auto",
    fontWeight: "600",
    color: "#fff",
    fontSize: "16px",
  },

  "@media (max-width: 420px)": {
    container: { width: "100%", height: "auto", padding: "10px" },
    content: {
      fontSize: "14px",
      lineHeight: "18px",
      marginBottom: "20px",
    },
    name: {
      fontSize: "18px",
      fontWeight: "500",
    },
    designation: {
      color: "#fff",
      fontSize: "16px",
    },
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    Background: {
      padding: "30px 0px",
    },
    container: { width: "100%", height: "auto", padding: " 30px 10px " },
  },
}));

function TestimonialCandidate(props) {
  const [showmore, setShowmore] = useState(false);
  const onClick = (event) => {
    event.stopPropagation();
    setShowmore(!showmore);
  };
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#262525",
          width: "auto",
          margin: "40px 40px",
          borderRadius: "8px",
          height: "380px",
          "@media (min-width: 1600px)": {
            textSizeAadjust: "none",
            margin: "20px 100px",
            marginBottom: "50px",
          },
          "@media (max-width: 420px)": {
            height: "350px",
            textSizeAadjust: "none",
            margin: "0px 30px 30px",
          },
        }}
      >
        {" "}
        <Box className={classes.container}>
          {" "}
          <Box
            component="img"
            src={props?.step.image}
            sx={{
              borderRadius: "50%",
              width: 80,
              height: 80,
              maxWidth: 400,
              overflow: "hidden",
              marginBottom: "20px",
              borderWidth: "2px ",
              borderStyle: "solid",
              borderColor: "#333",
            }}
          />
          <Box>
            <Box className={classes.name} component="span">
              {props?.step.name}
            </Box>{" "}
            {/* <Box className={classes.designation}>{props?.step.designation}</Box> */}
            <Box className={classes.content}>
              {showmore
                ? props?.step.content
                : `${props?.step.content.substring(0, 130)}`}
              {props?.step.content.length >= 130 && (
                <Button
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    color: "#3DCD84",
                    fontWeight: "500",
                    fontSize: "14px",
                    backgroundColor: "none",
                    "&:hover": {
                      backgroundColor: "none",
                      color: "#3DCD84",
                    },
                  }}
                  onClick={onClick}
                >
                  {showmore ? "See less..." : " See More..."}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TestimonialCandidate;
