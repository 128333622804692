import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import ClientTestimonialBg from "../../../../assets/images/candidate.png";
import TestimonialCandidate from "./candidate_card";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Kireet from "../../../../assets/Clients/1.png";
import Karthik from "../../../../assets/Clients/2.png";
import Srinivas from "../../../../assets/Clients/3.png";
import Afzal from "../../../../assets/Clients/4.png";
import CustomDots from "../../../../components/customDots/customDots";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica Neue",
  },
  Background: {
    backgroundImage: `url(${ClientTestimonialBg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "65px 80px",
  },
  title: {
    color: "#3DCD84",
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#fff",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 230px 30px",
  },
  rightArrow: {
    position: "absolute",
    zIndex: "999999",
    right: "-7px",
    top: "210px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  leftArrow: {
    position: "absolute",
    zIndex: "9999999",
    left: "0px",
    top: "210px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  "@media (max-width: 420px)": {
    Background: {
      width: "100%",
      padding: "50px 10px",
    },
    title: {
      fontSize: "26px",
      lineHeight: "28px",
      margin: "20px 0px",
    },
    subtitle: {
      fontSize: "20px",
      lineHeight: "22px",
      margin: "0px",
    },
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    Background: {
      padding: "30px 100px",
    },
  },
}));

const items = [
  {
    image: Kireet,
    content:
      "It was great experience with Leaders'Edge they helped me finding my dream job. Especially @Kruthika. Thanks a lot for helping me throughout the process. 🙏👏 ",
    name: "Kireet Kumar ",
    // designation: "Head Talent Acquisition India - John Deere",
    // quotation: `,,`,
  },
  {
    image: Karthik,
    content:
      "It was a good experience working with LeadersEdge and Team. Thanks for the follow-up till I receive my offer form BOSCH. ",
    name: "Karthik Tirumalasetty ",
    designation: "Sr. Manager HR - Yokogawa",
    // quotation: `,,`,
  },
  {
    image: Srinivas,
    content:
      "I have found LeadersEdge to be professional and efficient at what they do. They are eager to listen and improve which provides a continuous feedback to adapt with changing times and continue to excel ",
    name: "Srinivas Tapas ",
    designation: "Engineering Manager",
    // quotation: `,,`,
  },

  {
    image: Afzal,
    content:
      "The team is mature and understand the needs quickly. The response time and communication is also great. They have good processes established",
    name: "Afzal Shariff ",
    designation: "Manufacturing Quality Engineering Supervisor",
    // quotation: `,,`,
  },
];

function CandidateTestimonial() {
  const [showmore, setShowmore] = useState(false);
  const CustomRight = ({ onClick }) => (
    <button onClick={onClick} className={classes.rightArrow}>
      <ArrowForwardIosIcon style={{ fontSize: 30, color: "#3DCD84" }} />
    </button>
  );

  const CustomLeft = ({ onClick }) => (
    <button onClick={onClick} className={classes.leftArrow}>
      <ArrowBackIosIcon style={{ fontSize: 30, color: "#3DCD84" }} />
    </button>
  );
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Background} id="candidate">
        <Box className={classes.title}>What our candidate have to say</Box>
        {/* <Box className={classes.subtitle}>
          Elit duis tristique sollicitudin nibh sit amet commodo nulla. Ut
          tortor pretium. Quis viverra nibh cras pulvinar mattis nunc.
        </Box> */}
        <Box
          sx={{
            maxWidth: 1400,
            flexGrow: 1,
            margin: "0 auto",
            textAlign: "center",
            "@media (min-width: 1600px)": {
              textSizeAadjust: "none",
              maxWidth: 2200,
            },
          }}
        >
          <Carousel
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel
            infinite={true}
            partialVisbile
            focusOnSelect={true}
            // autoPlaySpeed={5000}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customDot={<CustomDots />}
          >
            {items.map((step, index) => (
              <TestimonialCandidate step={step} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
}

export default CandidateTestimonial;
