import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import BackgroundImg from "../../../assets/images/Our.png";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica Neue",
  },
  back: {
    backgroundColor: "#262525",
    margin: "0px",
    height: "auto",
    padding: "35px 0px 80px",
    textAlign: "center",
  },
  title: {
    paddingTop: "30px",
    color: "#3DCD84",
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "700",
    marginBottom: "10px",
  },
  imgFrame: {
    background: "#262525",
    width: "140px",
    height: "140px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto",
  },
  logo_img: {
    objectFit: "contain",
    margin: "0 auto",
    width: "130px",
    height: "130px",
    backgroundSize: "cover",
  },
  imgFrame1: {
    background: "#262525",
    width: "95px",
    height: "95px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto",
  },
  logo_img1: {
    objectFit: "contain",
    margin: "0 auto",
    width: "85px",
    height: "85px",
    backgroundSize: "cover",
  },
  "@media (max-width: 420px)": {
    imgFrame: {
      width: "100px",
      height: "100px",
    },
    logo_img: {
      objectFit: "contain",
      margin: "0 auto",
      width: "90px",
      height: "90px",
      backgroundSize: "cover",
    },
    back: {
      padding: "40px 10px 20px",
      margin: "0 auto",
      textAlign: "center",
    },
    title: {
      fontSize: "26px",
      lineHeight: "28px",
      margin: "20px 0px",
    },
  },
}));
const items = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Continuum.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Hindustan Platinum.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/John Deere.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Yokogawa.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Zetwerk.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Blueenergymotors.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ShandongHeavyIndustry.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/endurance.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schlumberger.png",
  },
];
const items2 = [
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bosch.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/MBRDI.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Bajaj Auto.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Cummins.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Schindler.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Wipro.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Tech Mahindra.png",
  },

  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/ProzealInfra.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Purplemonks.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/Sedme.png",
  },
  {
    image:
      "https://stagebucket.blob.core.windows.net/linkcxo/exotalentnewweb/exotalentnewweb.s3.ap-south-1.amazonaws.com/ET_Client_Logo/MRCC.png",
  },
];

const ClientsLogo = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.back} id='clients_logo'>
        <Box>
          <p className={classes.title}>Our Clients</p>
        </Box>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          direction='left'
          customTransition='all .5'
          transitionDuration={20}
          containerClass='carousel-container'
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          sx={{ paddingBottom: "-50px" }}>
          {items.map((step, index) => (
            <div key={step.label} className={classes.imgFrame}>
              <img src={step.image} className={classes.logo_img} />
            </div>
          ))}
        </Carousel>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          ssr={true}
          infinite={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          direction='left'
          customTransition='all .5'
          transitionDuration={20}
          containerClass='carousel-container'
          dotListClass='custom-dot-list-style'
          itemClass='carousel-item-padding-40-px'
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          sx={{ paddingBottom: "-50px" }}>
          {items2.map((step, index) => (
            <div key={step.label} className={classes.imgFrame1}>
              <img src={step.image} className={classes.logo_img1} />
            </div>
          ))}
        </Carousel>
      </Box>
    </>
  );
};
export default ClientsLogo;
