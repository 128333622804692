import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import {
  banner_section_Data,
  banner_section_Data1,
  banner_section_Data2,
  banner_section_Data3,
} from "../../../assets/Data/data";
import { useNavigate } from "react-router-dom";
import BackgroundImg from "../../../assets/images/banner.jpg";
import BackgroundMobile from "../../../assets/images/BGmobile.png";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  mainHome: {
    marginTop: "70px",
    display: "flex",
    alignItems: "center",
    margin: "auto",
    height: "100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    "&:before": {
      position: "absolute",
      content: `" "`,
      backgroundImage: `url(${BackgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh",
      width: "100%",
      backgroundPosition: "center",
      opacity: "0.5",
    },
  },
  carousel: { width: "60%", marginTop: "0px" },
  heading1: {
    marginBottom: "20px",
    fontSize: "42px",
    fontWeight: "700",
    color: "#fff",
    lineHeight: "45px",
  },
  heading: {
    fontSize: "32px",
    fontWeight: " 600",
    color: "#fff",
  },
  container: {
    width: "100%",
    padding: "0px 100px",
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    carousel: { width: "60%", marginTop: "40px" },
    container: {
      width: "100%",
      padding: "0px 120px",
    },
    heading: {
      paddingTop: "0px",
      fontSize: "34px",
      fontWeight: " 800",
    },
  },
  "@media (max-width: 1200px)": {
    heading: {
      fontSize: "24px",
      marginLeft: "20px",
    },
    text: {
      fontSize: "18px",
      marginLeft: "20px",
    },
  },
  "@media (max-width: 420px)": {
    knowbtn: { textAlign: "center" },
    carousel: { width: "100%", marginTop: "10px" },
    mainHome: {
      width: "100%",
      height: "400px",
      "&:before": { height: "400px", width: "100%" },
    },
    heading1: {
      color: "#fff",
      fontSize: "24px",
      textAlign: "center",
      lineHeight: "30px",
      marginBottom: "20px",
    },
    container: {
      padding: "0px 0px",
    },
    heading: {
      color: "#fff",
      textAlign: "center",
      padding: "0px",
      fontSize: "22px",
      marginBottom: "10px",
    },
  },
}));

const Banner = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleKnowMore = () => {
    navigate("/why_le_page");
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };
  return (
    <>
      <Box className={classes.mainHome} id="home">
        <Box className={classes.container}>
          <Box className={classes.carousel}>
            {" "}
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              autoPlay={true}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              direction="left"
              transitionDuration={20}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              <div style={{ width: "100%" }}>
                <p className={classes.heading1}>{banner_section_Data}</p>
                <p className={classes.heading}>{banner_section_Data1}</p>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <p className={classes.heading1}> {banner_section_Data2}</p>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <p className={classes.heading1}> {banner_section_Data3}</p>
              </div>
            </Carousel>
          </Box>
          <Box className={classes.knowbtn}>
            <Button
              onClick={handleKnowMore}
              variant="standard"
              sx={{
                marginTop: "25px",
                backgroundColor: "#3DCD84",
                borderRadius: "35px",
                color: "#262525",
                padding: "8px 25px",
                fontSize: "18px",
                textTransform: "none",
                fontWeight: " 800",
                "&:hover": {
                  color: "#262525",
                  backgroundColor: "#3DCD84",
                },
                "@media (max-width: 420px)": {
                  fontSize: "14px",
                  marginTop: "30px",
                  padding: "4px 20px",
                },
              }}
            >
              Know More
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Banner;
