import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack, shadows } from "@mui/material";
import { LeadercardDataT } from "../../../assets/Data/data";
import BackgroundImg from "../../../assets/images/banner.jpg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  black_card: {
    boxShadow: "0 0 15px -2px #706b6b",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    margin: "10px 40px",
  },
  black_card1: {
    textAlign: "center",
    backgroundColor: "#262525",
    padding: "10px",
    height: "70px",
  },
  black_card2: {
    textAlign: "center",
    backgroundColor: "#262525",
    padding: "10px",
    height: "100px",
  },

  "@media (max-width: 420px)": {
    black_card1: {
      padding: "10px 20px",
    },
    black_card2: {
      padding: "10px 20px",
    },
    heading: {
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "18px",
      margin: "20px 0px",
    },
    description: {
      textAlign: "center",
      display: "flex",
      fontSize: "14px",
      lineHeight: "15px",
      margin: "0px 0px 30px 0px",
    },
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    black_card1: {
      padding: "30px 0px",
      height: "70px",
    },
    black_card2: {
      padding: "30px 0px",
      height: "120px",
    },
    black_card: {
      margin: "10px 80px",
    },
  },
}));
const Leadership = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.black_card}>
        <Box className={classes.black_card1}>
          <p
            style={{
              margin: "10px 0px",
              lineHeight: "20px",
              color: "#3DCD84",
              fontSize: "18px",
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            {props?.step.heading}
          </p>
        </Box>
        <Box className={classes.black_card2}>
          <p
            style={{
              color: "#fff",
              fontSize: "16px",
              lineHeight: "18px",
            }}
          >
            {props?.step.description}{" "}
          </p>
        </Box>
      </Box>
    </>
  );
};
export default Leadership;
