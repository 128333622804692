import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";

import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import Footer from "../../../../components/footer/footer";
import Gyanesh from "../../../../assets/Teams/Gyanesh.png";
import Hema from "../../../../assets/Teams/Hema.png";
import Sumit from "../../../../assets/Teams/Sumit.png";
import Varun from "../../../../assets/Teams/Varun.png";
import Rajesh from "../../../../assets/Teams/Rajesh.jpg";
import Mohit from "../../../../assets/images/mohit.png";
import Vishwas from "../../../../assets/Teams/viswas.jpg";
import Ananjan from "../../../../assets/Teams/ananjan.jpg";
import Chetna from "../../../../assets/Teams/chetna.jpg";
import Shubhashish from "../../../../assets/Teams/subhashish.jpg";

import BackgroundImg from "../../../../assets/images/Teambg.png";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    paddingTop: "50px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  heading1: {
    marginTop: "32px",
    fontSize: "24px",
    fontWeight: "700",
    color: "#3DCD84",
    textAlign: "center",
  },
  heading2: {
    marginTop: "30px",
    lineHeight: "22px",
    fontSize: "18px",
    fontWeight: "700",
    color: "#fff",
    textAlign: "center",
  },
  image: {
    marginTop: "30px",
    width: "120px",
    height: "120px",
    backgroundColor: "#fff",
  },
  position: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
  },
  text: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left",
    marginLeft: "25%",
    // listStyleType: "none",
  },
  text3: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left",
    marginLeft: "32%",
    // listStyleType: "none",
  },
  text1: {
    color: "#3DCD84",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "10px",
  },
  text2: {
    margin: "0px 10px",
    lineHeight: "18px",
  },
  marketing: { padding: "0px 100px" },
  container: {
    padding: " 60px 80px",
    textAlign: "center",
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    main: { padding: "50px 100px" },

    heading: {
      paddingTop: "0px",
      fontSize: "34px",
      fontWeight: " 800",
    },
    heading1: {
      marginTop: "80px",
    },
    heading2: {
      lineHeight: "24px",
      fontSize: "20px",
      marginBottom: "30px",
    },
    text: {
      fontSize: "14px",
    },
    container: {
      padding: " 60px 0px",
      textAlign: "center",
    },
  },

  "@media (max-width: 420px)": {
    main: {
      padding: "0px",
    },
    heading: {
      marginTop: "50px",
      fontSize: "28px",
    },
    heading1: {
      fontSize: "24px",
    },
    container: {
      padding: "40px 10px",
      textAlign: "center",
    },
    marketing: { padding: "0px 10px", textAlign: "center" },
    text: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
}));
const Teams = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Box className={classes.main}>
        <Box className={classes.container}>
          {" "}
          <Box>
            {" "}
            <p className={classes.heading}>Meet Our Team</p>
          </Box>
          <Box>
            <p className={classes.heading1}>Co-Founders & Advisors</p>
          </Box>
          <Box>
            <p className={classes.heading2}>
              Our founding team comes with great experience of different
              business domains. They make our core and bring synergy to the team
              with their skill set and competencies.
            </p>
          </Box>
          <Box>
            {" "}
            <Grid sm={12} md={12} container>
              {" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.image} component="img" src={Gyanesh} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Gyanesh Kumar</p>
                  <p className={classes.position}>
                    Co-founder & Managing Partner
                  </p>
                </Box>
                <Box
                  sx={{
                    paddingRight: "40px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    {" "}
                    <li>Founder & CEO - LinkCXO Global Pvt. Ltd. </li>
                    <li>Co-Founder & Senior Partner - ExoTalent Consultancy</li>
                    <li>
                      18+ years of global industry experience across US, APAC
                      and EMEA geography.
                    </li>
                    <li>
                      <span> LinkedIn Profile:</span>

                      <span>
                        <a
                          target="blank"
                          href="https://www.linkedin.com/in/gyanesh/"
                          style={{ color: "white" }}
                        >
                          https://www.linkedin.com/in/gyanesh/
                        </a>
                      </span>
                    </li>
                  </ul>{" "}
                </Box>
              </Grid>{" "}
              <Grid Item xs={12} sm={6}>
                <Box className={classes.image} component="img" src={Hema} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Hema Gupta</p>
                  <p className={classes.position}>Co-Founder</p>
                </Box>
                <Box
                  sx={{
                    paddingRight: "40px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    <li>Co-founder & Senior Partner-ExoTalent Consultancy</li>
                    <li>Co-founder- LinkCXO Global Pvt. Ltd. </li>
                    <li>
                      Automotive Business Head with 16+ years of experience.
                    </li>
                    <li>
                      Highly skilled in client management with special focus on
                      end to end RPO service.
                    </li>
                    <li>
                      <span> LinkedIn Profile:</span>

                      <span>
                        <a
                          target="blank"
                          href="https://www.linkedin.com/in/hema-gupta-82561924/"
                          style={{ color: "white" }}
                        >
                          https://www.linkedin.com/in/hema-gupta-82561924/
                        </a>
                      </span>
                    </li>
                  </ul>{" "}
                </Box>
              </Grid>
              {/* <Grid Item xs={12} sm={6}>
                <Box className={classes.image} component="img" src={Mohit} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Mohit Kumar</p>
                  <p className={classes.position}>Principal Consultant</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "100px",
                    paddingRight: "40px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    <li>
                      10+ years of experience across London, UK; Dubai & Toronto
                      with leading tier 1 executive search firms.
                    </li>
                    <li>
                      Expertise in Technology market and worked in emerging tech
                      domain like IoT, Tokenization, Decentralized Currency,
                      Blockchain, API etc for his clients.
                    </li>

                    <li>
                      He has executed global assignments for complex
                      organizations on Leadership level like CEO, MD, CFO etc..
                      and, at functional level like CIO, Business Heads &
                      Regional Heads etc.
                    </li>
                    <li>
                      <span> LinkedIn Profile:</span>

                      <span>
                        <a
                          target="blank"
                          href="https://www.linkedin.com/in/mohit-k-56896544/details/experience/"
                          style={{ color: "white" }}
                        >
                          https://www.linkedin.com/in/mohit-k-56896544/details/experience/
                        </a>
                      </span>
                    </li>
                  </ul>{" "}
                </Box>
              </Grid> */}
              <Grid Item xs={12} sm={12}>
                <Box className={classes.image} component="img" src={Rajesh} />
                <Box>
                  <p className={classes.text1}>Rajesh Padmanabhan </p>
                  <p className={classes.position}>Advisor</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "100px",
                    paddingRight: "40px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                      paddingRight: "50px",
                    },
                  }}
                >
                  <ul className={classes.text3}>
                    <li>
                      CHRO (IGate, Capgemini & Essel Propack,Vedanta, Welspun &
                      Oberoi)
                    </li>
                    <li>CEO - Talavvy</li>
                    <li>Business Head - ICICI Bank</li>
                    <li>
                      Business Consulting & SAP - Capgemini & 3i Infotech.
                    </li>
                    <li>
                      Won the prestigious UN award for building world class
                      diversity & inclusion practices plus many other industry
                      accolades.
                    </li>
                  </ul>{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <p className={classes.heading1}>Consulting Partners</p>
          </Box>
          <Box>
            <p className={classes.heading2}>
              We have team of Subject Matter Experts and consulting partners who
              bring years of industry experience and come with a good knowledge
              and skill set to guide us in our journey.
            </p>
          </Box>
          <Box>
            {" "}
            <Grid sm={12} md={12} container>
              {" "}
              <Grid Item xs={12} sm={4}>
                <Box className={classes.image} component="img" src={Sumit} />
                <Box>
                  <p className={classes.text1}>Sumit Kumar </p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    {" "}
                    <li>
                      17+ years of experience spanning across countries like
                      India, the US and Netherlands.
                    </li>
                    <li>Specializes in AI and ML.</li>
                    <li>B.Tech and M.Tech - IIT Kharagpur.</li>
                    <li>
                      FPM in AI/ML high frequency financial data - IIM Shillong.
                    </li>
                    <li>His experience spans India, the US and Netherlands.</li>
                  </ul>{" "}
                </Box>
              </Grid>
              <Grid Item xs={12} sm={4}>
                <Box className={classes.image} component="img" src={Vishwas} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Vishwas Vaidya</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  {" "}
                  <ul className={classes.text}>
                    {" "}
                    <li>M.Tech from IIT(Delhi)in Control Engineering.</li>
                    <li>
                      Extensive experience for 33 years in the area of
                      engineering research in the industry.
                    </li>
                    <li>
                      Worked in many organizations such as Spark Minds, Tata
                      Motors, TIFR and HCL.
                    </li>
                    {/* <li>
                      Engaged in research, academics and development in the area
                      of automotive embedded system in general.
                    </li> */}
                    <li>
                      Filed more than 25 patents and an equal number of research
                      papers.
                    </li>
                  </ul>{" "}
                </Box>
              </Grid>{" "}
              <Grid Item xs={12} sm={4}>
                <Box className={classes.image} component="img" src={Varun} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Varun Kalia</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                      paddingRight: "50px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    {" "}
                    <li>Founder and Director at HOWBUS.</li>
                    <li>Chartered Accountant and a SRCC alumnus.</li>
                    <li>
                      Finance Professional with 20 plus years of proven ability
                      to deliver on goals in high pressure environment in MNCs.
                    </li>
                    <li>
                      Worked with Ernst & Young, GE Capital, Arthur Anderson and
                      other financial organizations.
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
            <Grid sm={12} md={12} container>
              {" "}
              <Grid Item xs={12} sm={4}>
                <Box className={classes.image} component="img" src={Chetna} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Chetna Israni</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  {" "}
                  <ul className={classes.text}>
                    {" "}
                    <li>Co-Founder at Morning Star Brandcom LLP.</li>
                    <li>IIM Calcutta and Mumbai University alumnus.</li>
                    <li>
                      Marketing and communicational Professional with 12 plus
                      years of experience in setting up processes from the
                      ground up and strategizing marketing plans to match
                      business needs.
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid Item xs={12} sm={4}>
                <Box className={classes.image} component="img" src={Ananjan} />
                <Box>
                  {" "}
                  <p className={classes.text1}>Ananjan Santra</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                    },
                  }}
                >
                  {" "}
                  <ul className={classes.text}>
                    {" "}
                    <li>
                      MBA from IIT Kanpur.
                      {/* and
                        certificate course from Sloan
                        School of Management and Columbia Business School */}
                    </li>
                    <li>
                      Extensive experience for 33 years in the area of
                      engineering research in the industry.
                    </li>
                    <li>
                      Worked in many organizations such as Spark Minds, Tata
                      Motors, TIFR and HCL.
                    </li>
                    {/* <li>
                        Engaged in research, academics and development in the
                        area of automotive embedded system in general
                      </li> */}
                    <li>
                      Filed more than 25 patents and an equal number of research
                      papers.
                    </li>
                  </ul>{" "}
                </Box>
              </Grid>{" "}
              <Grid Item xs={12} sm={4}>
                <Box
                  className={classes.image}
                  component="img"
                  src={Shubhashish}
                />
                <Box>
                  {" "}
                  <p className={classes.text1}>Subhashish Mazumdar</p>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "60px",
                    "@media (max-width: 420px)": { paddingLeft: "35px" },
                    "@media (min-width: 1600px)": {
                      textSizeAadjust: "none",
                      paddingLeft: "100px",
                      paddingRight: "50px",
                    },
                  }}
                >
                  <ul className={classes.text}>
                    {" "}
                    <li>
                      IIM Ahmedabad alumnus of 1990 batch.
                      {/* and Lucknow
                        University alumnus */}
                    </li>
                    {/* <li>
                        Have more than 32 years of managerial experience in
                        diverse functions with multiple industry exposure
                      </li> */}
                    <li>
                      Worked with Tata Group for around seven years and
                      Multinationals (Rhone Poulenc, Aventis, Hinduja) for
                      around 30 years.
                    </li>
                    <li>
                      Member of Task Force , Govt of India. Ministry of
                      Information and Broadcasting from 2010 to 2017.
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
export default Teams;
