import { combineReducers } from "redux";
import { authreducer as auth } from "./auth";
import { messagereducer as message } from "./message";
import storage from "redux-persist/lib/storage";

const reducers = {
  auth,
  message,
};
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_FULFILLED") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
