import React, { useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import BackgroundImage1 from "../../../assets/images/about.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import TabUnstyled from "@mui/base/TabUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import Executive from "./execuiteve_ser";
import Leadership from "./leadership";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica",
  },
  main: {
    backgroundImage: `url(${BackgroundImage1})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    paddingTop: "20px",
  },
  title: {
    color: "#3DCD84",
    fontSize: "36px",
    fontWeight: "700",
    marginBottom: "20px",
  },
  text: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "22px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  container: {
    padding: "50px 100px",
    textAlign: "center",
  },
  tabname: {
    fontWeight: "600",
    fontSize: "17px",
    lineHeight: "20px",
  },
  tabdigit: {
    fontWeight: "700",
    fontSize: "18px",
  },
  rightArrow: {
    position: "absolute",
    zIndex: "999999",
    right: "-7px",
    top: "80px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  leftArrow: {
    position: "absolute",
    zIndex: "9999999",
    left: "0px",
    top: "80px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  // stackbox: { marginTop: "40px" },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    title: {
      color: "#3DCD84",
      fontSize: "36px",
      fontWeight: "700",
      marginBottom: "20px",
    },
    text: {
      color: "#fff",
      fontSize: "17px",
      lineHeight: "22px",
      marginTop: "20px",
      marginBottom: "30px",
    },
    container: {
      padding: "50px 100px",
      textAlign: "center",
    },

    rightArrow: {
      top: "90px",
    },
    leftArrow: {
      top: "90px",
    },
  },

  "@media (max-width: 420px)": {
    main: {
      display: "none",
      padding: "0px 20px",
    },
    container: {
      padding: "0px 0px",
    },
    text: {
      color: "#fff",
      fontSize: "16px",
      lineHeight: "18px",
      marginTop: "20px",
    },
  },
}));
const Tab = styled(TabUnstyled)((theme) => ({
  color: "white",
  cursor: "pointer",
  padding: "5px 0px",
  margin: "6px",
  border: "none",
  borderRadius: "5px",
  display: "flex",
  fontSize: "18px",
  justifyContent: "center",
  backgroundColor: "transparent",
  fontFamily: "Helvetica",
  fontWeight: "700",

  "& .MuiTabs-indicator": {
    color: "#38CD84",
    backgroundColor: "none",
  },
  "&.MuiTab-root": {
    borderBottom: 0,
  },
  "& .Mui-disabled": {
    color: "rgb(245,245,245, 0.6)!important",
  },
  "&:hover": {
    backgroundColor: "none",
  },
  "&.Mui-selected": {
    fontWeight: "700",
    fontSize: "18px",
    backgroundColor: "#38CD84",
    color: "#262525",
    // padding: "4px 20px",
  },
}));

const TabsList = styled(TabsListUnstyled)((theme) => ({
  // maxWidth: "800px",
  padding: "0px 100px",
  borderRadius: "8px",
  marginBottom: "30px",
  display: "flex",
  // alignItems: "center",
  margin: "30px 80px",
  justifyContent: "space-around",
  // alignContent: "center",
  // padding: "15px 150px 0px",
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    // minWidth: "800px",
    // padding: "15px 250px 0px",
    margin: "30px 185px",
  },
}));

const TabPanel = styled(TabPanelUnstyled)((theme) => ({
  width: "100%",
}));
const item_executive = [
  {
    heading: "CXO Mapping",
    description:
      "We have a huge network of CXOs and a vast database of candidates.",
  },

  {
    heading: "Niche Search",
    description:
      "Technology aided back end with AI & web crawlers enabled passive search.",
  },

  {
    heading: "Diversity Search",
    description: "We emphasize our search on diversity reach of candidates",
  },

  {
    heading: "State of the art assessments",
    description:
      "We mine new age capabilities additionally in today's context...",
  },

  {
    heading: "Start-Ups & Greenfield Set-Ups",
    description:
      "We have profound expertise in setting up the Senior management team...",
  },
];

const item_leadership = [
  {
    heading: "Top Team Effectiveness ",
    description:
      "Organizations that pursue and sustain high-performing senior leadership...",
  },

  {
    heading: "Leadership Restructuring",
    description:
      "This is an area of expertise across various domains & we help our clients...",
  },

  {
    heading: "Leadership Assimilation",
    description:
      "This offering enables a hired executive to land into the system well.",
  },

  {
    heading: "Executive Assessment",
    description:
      "Our experienced consultants and senior industry leaders on the panel advise...",
  },

  {
    heading: "Organisation Structure",
    description:
      "We help our clients design the right Organisation structure to deliver the business objective",
  },

  {
    heading: "Merger & Acquisition Leadership Advisory",
    description:
      "Every M&A has a potential issue of getting into Leadership Surplus or Deficit",
  },

  {
    heading: "Capability Building",
    description:
      "We provide capability building frameworks & delivery models that allows our clients to reinforce skills",
  },

  {
    heading: "Succession planning",
    description:
      "We bring an integrated and systematic approach for identifying and developing right successor/s",
  },
];

const WhatWeDo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleSeeMore = () => {
    navigate("/what_we_do_detail");
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isActive, setIsActive] = useState(false);
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const CustomRight = ({ onClick }) => (
    <button onClick={onClick} className={classes.rightArrow}>
      <ArrowForwardIosIcon style={{ fontSize: 24, color: "white" }} />
    </button>
  );

  const CustomLeft = ({ onClick }) => (
    <button onClick={onClick} className={classes.leftArrow}>
      <ArrowBackIosIcon style={{ fontSize: 24, color: "white" }} />
    </button>
  );
  return (
    <>
      <Box className={classes.main} id="what_we">
        <Box className={classes.container}>
          {" "}
          <Box>
            {" "}
            <p className={classes.title}> What We Do</p>
          </Box>
          <Box>
            <p className={classes.text}>
              As a trusted Leadership Search & Consulting House, at Leaders'Edge
              all our efforts stem from the understanding that in order to make
              effective change, leaders need to be given an appropriate platform
              to lead. What sets us apart is the local insights we bring to the
              table. Packed with industry knowledge, our consultants go above
              and beyond to cater to your specific needs. We strongly believe in
              developing long term relationships with our clients, to build that
              trust and understanding that's otherwise hard to come by. Our
              extensive clientele and over 90% client retention rate, speak
              volumes of what we're capable of. We use a technology aided
              backend with AI & web crawlers enabled passive search. We go deep
              to Mine Leaders.
            </p>
          </Box>
          <Box className={classes.tab_container}>
            <Box>
              <TabsUnstyled
                defaultValue={0}
                value={value}
                onChange={handleChange}
                color="white"
                aria-label="ant example"
              >
                <TabsList>
                  {/* <Box xs={12} sm={2}></Box> */}
                  <Tab>
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontWeight: "600",
                        fontSize: "17px",
                        lineHeight: "20px",
                        padding: "4px 20px",
                      }}
                    >
                      Executive Services
                    </Typography>
                  </Tab>
                  <Tab>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        fontSize: "17px",
                        lineHeight: "20px",
                        padding: "4px 20px",
                      }}
                    >
                      Consulting/Advisory
                    </Typography>
                  </Tab>
                  {/* <Box xs={12} sm={2}></Box> */}
                </TabsList>

                <TabPanel value={0}>
                  {" "}
                  <Box
                    xs={12}
                    sm={6}
                    sx={{
                      maxWidth: 1400,
                      flexGrow: 1,
                      margin: "0 auto",
                      textAlign: "center",
                      "@media (min-width: 1600px)": {
                        textSizeAadjust: "none",
                        maxWidth: 2000,
                      },
                    }}
                  >
                    <Carousel
                      customRightArrow={<CustomRight />}
                      customLeftArrow={<CustomLeft />}
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      autoPlay={false}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlaySpeed={8000}
                      keyBoardControl={true}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                      className="main-what-our-member-style-section"
                    >
                      {item_executive.map((step, index) => (
                        <Executive step={step} />
                      ))}
                    </Carousel>
                  </Box>
                </TabPanel>

                <TabPanel value={1}>
                  {" "}
                  <Box
                    xs={12}
                    sm={6}
                    sx={{
                      maxWidth: 1400,
                      flexGrow: 1,
                      margin: "0 auto",
                      textAlign: "center",
                      "@media (min-width: 1600px)": {
                        textSizeAadjust: "none",
                        maxWidth: 2000,
                      },
                    }}
                  >
                    <Carousel
                      customRightArrow={<CustomRight />}
                      customLeftArrow={<CustomLeft />}
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      autoPlay={false}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlaySpeed={8000}
                      keyBoardControl={true}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      {item_leadership.map((step, index) => (
                        <Leadership step={step} />
                      ))}
                    </Carousel>
                  </Box>
                </TabPanel>
              </TabsUnstyled>
            </Box>
          </Box>
          <Box className={classes.knowbtn}>
            <Button
              onClick={handleSeeMore}
              variant="standard"
              sx={{
                marginTop: "20px",
                backgroundColor: "none",
                lineHeight: "26px",
                color: "#3DCD84",
                fontSize: "22px",
                textTransform: "none",
                padding: "10px 20px",
                fontWeight: " 800",
                "&:hover": {
                  color: "#3DCD84",
                  backgroundColor: "none",
                },
                "@media (max-width: 420px)": {
                  fontSize: "14px",
                  marginTop: "30px",
                  padding: "4px 20px",
                },
              }}
            >
              See More
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default WhatWeDo;
