import * as React from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer/footer";
import Banner from "../../container/home/top_banner/banner_section";
import WhatWeDo from "../../container/home/what_we_do/what_we_do";
import WhatWeMobile from "../../container/home/what_we_do/what_we_mobile_view";
import WhyLe from "../../container/home/about_us/why_LE/why_le";
import ClientTestimonial from "../../container/home/about_us/client_testimonial/client_testimonial";
import OurMission from "../../container/home/about_us/our_mission/our_mission_corevalue";
import CandidateTestimonial from "../../container/home/about_us/candidate_testimonial/candidate_testimonial";
import { Box } from "@mui/material";
import Stat from "../../container/home/stat/stat";
import ClientsLogo from "../../container/home/our_clients_logo/clients_logo";

const Home = () => {
  return (
    <Box>
      {" "}
      <Navbar />
      <Banner />
      <WhatWeMobile />
      <WhatWeDo id="what_we" />
      <Stat />
      <WhyLe />
      <OurMission />
      <ClientsLogo />
      <ClientTestimonial />
      <CandidateTestimonial />
      <Footer />
    </Box>
  );
};

export default Home;
