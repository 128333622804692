import { ThemeProvider, createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
// import overrides from "./overrides";

const theme = createTheme({
  typography,
  palette: {
    mode: "dark",
    secondary: "#38CD84",
    dark: "#1F1E1E",
    main: "#262525",
    color: {
      dark: "#1F1E1E",
      main: "#262525",
    },

    secondary: {
      light: "#e6f0e6",
      main: "#38CD84",
      dark: "#38CD84",
      contrastText: "#000",
    },

    palette,
  },
});

export default theme;
