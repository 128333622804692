import React, { useState } from "react";
import { Grid, Box, TextField, Button, Stack } from "@mui/material";
import ClientTestimonialBg from "../../../../assets/images/clientsay.png";
import TestimonialClient from "./client_card";
import { makeStyles } from "@mui/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import payal from "../../../../assets/Clients/payalnew.jpg";
import kamla from "../../../../assets/Clients/Kamla.jpeg";
import murtaza from "../../../../assets/Clients/Murtaza.jpeg";
import santosh from "../../../../assets/Clients/Santosh.jpeg";
import CustomDots from "../../../../components/customDots/customDots";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Helvetica Neue",
  },
  Background: {
    backgroundImage: `url(${ClientTestimonialBg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "65px 80px",
  },
  title: {
    color: "#3DCD84",
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#fff",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 230px 30px",
  },
  rightArrow: {
    position: "absolute",
    zIndex: "999999",
    right: "-7px",
    top: "210px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  leftArrow: {
    position: "absolute",
    zIndex: "9999999",
    left: "0px",
    top: "210px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    border: "none",
    background: "none",
    outline: "none",
  },
  "@media (max-width: 420px)": {
    Background: {
      width: "100%",
      padding: "50px 10px",
    },
    title: {
      fontSize: "26px",
      lineHeight: "28px",
      margin: "20px 0px",
    },
    subtitle: {
      fontSize: "20px",
      lineHeight: "22px",
      margin: "0px",
    },
  },
  "@media (min-width: 1600px)": {
    textSizeAadjust: "none",
    Background: {
      padding: "30px 100px",
    },
  },
}));

const items = [
  {
    image: payal,
    content:
      "I completely agree our partnership is highly valued and the journey of the last 5 years has been enriching and rewarding. The external talent market dynamics in the last 2-3 years has been drastically changing, it's a VUCA world, it's the candidates world with multiple offers in hand, war for talent etc , but must say LeadersEdge has always taken the challenge and never did we dry up on the inflow of resumes. I highly appreciate the hard work done by Richa , Bhagya and the entire backend team. Thank you Hema for taking the ownership of hiring the right talent for John Deere.",
    name: "Payal S",
    designation: "Head Talent Acquisition India - John Deere",
    // quotation: `,,`,
  },
  {
    image: kamla,
    content:
      "I have been working with LeadersEdge for the past 4 years, Your conversion rate from profile to shortlist is pretty good. The quality of service mainly for mid-senior positions and IT space is very good and your response is very quick in every stage of recruitment. Thank you for being our partners in our growth Journey.",
    name: "Kamalapriya Mohanarangan",
    designation: "Sr. Manager HR - Yokogawa",
    // quotation: `,,`,
  },
  {
    image: murtaza,
    content:
      "I have found LeadersEdge to be professional and efficient at what they do. They are eager to listen and improve which provides a continuous feedback to adapt with changing times and continue to excel ",
    name: "Murtaza Hita",
    designation: "Engineering Manager",
    // quotation: `,,`,
  },

  {
    image: santosh,
    content:
      "The team is mature and understand the needs quickly. The response time and communication is also great. They have good processes established",
    name: "Santhosh Venkateshaiah",
    designation: "Manufacturing Quality Engineering Supervisor",
    // quotation: `,,`,
  },
];

function ClientTestimonial() {
  const CustomRight = ({ onClick }) => (
    <button onClick={onClick} className={classes.rightArrow}>
      <ArrowForwardIosIcon style={{ fontSize: 30, color: "white" }} />
    </button>
  );

  const CustomLeft = ({ onClick }) => (
    <button onClick={onClick} className={classes.leftArrow}>
      <ArrowBackIosIcon style={{ fontSize: 30, color: "white" }} />
    </button>
  );
  const [showmore, setShowmore] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Background} id="client">
        <Box className={classes.title}>What our clients have to say</Box>

        <Box
          sx={{
            maxWidth: 1400,
            flexGrow: 1,
            margin: "0 auto",
            textAlign: "center",
            "@media (min-width: 1600px)": {
              textSizeAadjust: "none",
              maxWidth: 2200,
            },
          }}
        >
          <Carousel
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel
            infinite={true}
            partialVisbile
            focusOnSelect={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customDot={<CustomDots />}
          >
            {items.map((step, index) => (
              <TestimonialClient step={step} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
}

export default ClientTestimonial;
